<template>
  <b-container>
    <GroupForm
      :data="data"
      :merchants="merchants"
    >
      <template #append-form>
        <b-button
          variant="primary"
          @click="create"
        >
          Criar grupo
        </b-button>
      </template>
    </GroupForm>
  </b-container>
</template>

<script>
import {
    BContainer, BButton,
} from 'bootstrap-vue';

import GroupForm from './Form/GroupForm.vue';

import EcommerceGroupService from '@/service/merchant/mercury/group';
import EcommerceUserService from '@/service/merchant/mercury/merchant';

export default {
    components: {
        BContainer, GroupForm, BButton,
    },

    data() {
        return {
            data: {
                permissions: [3, 4, 5, 9, 10, 11],
            },
            merchants: [],
        };
    },

    async mounted() {
        const { data } = await EcommerceUserService.getAll(1);
        this.merchants = data.data;
    },

    methods: {
        async create() {
            const response = await this.confirmAnAction('Deseja realmente criar esse grupo?');

            if (!response) {
                return;
            }

            const { status } = await EcommerceGroupService.createNewGroup(this.data);

            if (status !== 201) {
                this.modalError('Erro ao criar grupo');
                return;
            }

            this.modalSuccess('Grupo criado com sucesso');
            this.$router.push('/ecommerce');
        },
    },
};
</script>
